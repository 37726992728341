.forkphorus-root {
  width: 480px;
  height: 360px;
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  color-scheme: light;
  background-color: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 10px;
  position: relative;
  overflow: hidden;
}

.forkphorus-root > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.s3-watcher-container {
  color: #575e75;
  pointer-events: auto;
  background: #e6f0ff;
  border: .1em solid #00000026;
  border-radius: .25em;
  flex-direction: column;
  line-height: 1.25;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.s3-watcher-container > * {
  font-size: 1.2em;
}

.s3-watcher-row {
  flex-direction: row;
  display: flex;
}

.s3-watcher-label {
  text-align: center;
  margin: 0 5px;
  font-weight: bold;
}

.s3-watcher-value {
  color: #fff;
  text-align: center;
}

.s3-watcher-container .s3-watcher-value {
  background-color: #0fbd8c;
}

.s3-watcher-container[data-opcode^="data_"] .s3-watcher-value {
  background-color: #ff8c1a;
}

.s3-watcher-container[data-opcode^="sensing_"] .s3-watcher-value {
  background-color: #5cb1d6;
}

.s3-watcher-container[data-opcode^="motion_"] .s3-watcher-value {
  background-color: #4c97ff;
}

.s3-watcher-container[data-opcode^="looks_"] .s3-watcher-value {
  background-color: #96f;
}

.s3-watcher-container[data-opcode^="sound_"] .s3-watcher-value {
  background-color: #cf63cf;
}

.s3-watcher-container-normal {
  padding: 3px;
}

.s3-watcher-row-normal .s3-watcher-value {
  min-width: 4em;
  border-radius: .25em;
  margin: 0 .5em;
  padding: 0 .2px;
}

.s3-watcher-row-slider input[type="range"] {
  width: 100%;
  margin: 0;
}

.s3-watcher-large .s3-watcher-value {
  border-radius: inherit;
  min-height: 1.4em;
  min-width: 3em;
  font-size: 1.6em;
}

.s3-list-container {
  color: #575e75;
  pointer-events: none;
  background-color: #e6f0ff;
  border: .1em solid #00000026;
  border-radius: .25em;
  flex-direction: column;
  line-height: 1.5;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.s3-list-content {
  text-align: center;
  width: 100%;
  pointer-events: auto;
  scrollbar-color: auto;
  flex: 1;
  overflow-y: auto;
}

.s3-list-content:empty:after {
  content: "(empty)";
  text-align: center;
}

.s3-list-rows {
  width: 100%;
  height: 100%;
  contain: layout;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.s3-list-endpoint {
  width: 1px;
  height: 1px;
  will-change: transform;
  contain: layout;
  position: absolute;
}

.s3-list-row {
  width: 100%;
  text-align: left;
  will-change: transform;
  contain: layout;
  flex-direction: row;
  align-items: center;
  display: flex;
  position: absolute;
}

.s3-list-index, .s3-list-value {
  margin: 0 3px;
}

.s3-list-index {
  pointer-events: none;
  font-weight: bold;
}

.s3-list-value {
  color: #fff;
  min-height: 1.5em;
  text-overflow: ellipsis;
  -webkit-user-select: text;
  user-select: text;
  white-space: pre;
  background-color: #fc662c;
  border: 1px solid #00000026;
  border-radius: .25em;
  flex: 1;
  padding: 1px 5px;
  overflow: hidden;
}

.s3-list-top-label, .s3-list-bottom-label {
  text-align: center;
  background-color: #fff;
  padding: 3px;
  font-weight: bold;
}

.s3-list-top-label {
  border-bottom: 1px solid #00000026;
}

.s3-list-row, .s3-list-top-label, .s3-list-bottom-label {
  font-size: 1.2em;
}

.player-root {
  color: #000;
  height: auto;
}

.player-root:not([fullscreen]) .player-stage {
  width: 480px;
  height: 360px;
}

.player-root[fullscreen] .player-stage {
  margin-right: calc(var(--lower-width-by) / 2);
  border: .1875rem solid #7e8597;
}

.player-root[fullscreen] {
  height: 100%;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.player-root .player-click-to-play-container {
  display: none;
}

.player-root[running] .player-flag[class][class][class][class], .player-flag[class][class][class][class]:active {
  background-color: #4c97ff59;
  border-radius: .25rem;
}

.player-body-fullscreen {
  overflow: hidden;
}

.player-root .player-error {
  color: maroon;
  box-sizing: border-box;
  width: 100%;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  pointer-events: auto;
  background-color: #ffc8c8e6;
  padding: 8px;
  font: 500 12px sans-serif;
  position: relative;
  top: 100%;
  left: 0;
  transform: translateY(-100%);
}

.player-error a {
  color: #640000;
}

.player-error a:active {
  color: #1843d2;
}

.player-root[theme="dark"] .player-error {
  color: #ff5b5b;
  background-color: #280808d9;
}

.player-root[theme="dark"] .player-error a {
  color: #d21818;
}

.player-root[theme="dark"] .player-error a:active {
  color: #1878d2;
}

.player-controls {
  height: 32px;
  position: relative;
  overflow: hidden;
}

.player-controls .player-button {
  width: 32px;
  height: 32px;
  float: left;
  cursor: pointer;
  text-align: center;
  opacity: .4;
  background-image: url("forkphorus-icons.35ac16e1.svg");
  text-decoration: none;
}

.player-root[theme="dark"] .player-button {
  opacity: .6;
  background-position-y: -32px;
}

.player-controls .player-button:active {
  opacity: 1;
}

.player-controls .player-label {
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  color: #0006;
  padding: 0 8px;
  font: 500 12px / 32px sans-serif;
}

.player-root[theme="dark"] .player-label {
  color: #fff9;
}

.player-controls .player-fullscreen-btn {
  float: right;
  background-position: -128px 0;
}

.player-root[fullscreen] .player-controls .player-fullscreen-btn {
  background-position-x: -160px;
}

.player-root[fullscreen] .control-background-thingy {
  display: block;
}

.player-controls .player-flag {
  background-position: 0 0;
}

.player-controls .player-stop {
  background-position: -96px 0;
}

.player-controls .player-pause {
  background-position: -32px 0;
}

.player-root:not([running]) .player-controls .player-pause {
  background-position-x: -64px;
}

.player-controls .player-turbo {
  display: none;
}

.player-root[turbo] .player-turbo {
  color: var(--secondary-color);
  font-size: .625em;
  font-weight: bold;
  display: block;
  position: absolute;
  left: 64px;
}

.player-controls .player-muted {
  float: left;
  cursor: pointer;
  display: none;
}

.player-controls .player-muted:hover {
  text-decoration: underline;
}

@keyframes player-appear {
  from {
    visibility: hidden;
  }

  to {
    visibility: visible;
  }
}

.player-root[running][audio-state="suspended"] .player-controls .player-muted {
  animation-name: player-appear;
  animation-duration: .5s;
  animation-timing-function: step-end;
  display: block;
}

.control-background-thingy {
  width: 100vw;
  height: 2.75rem;
  background-color: #e8edf1;
  display: none;
  position: absolute;
  left: 0;
}

.player-progress {
  width: 100%;
  height: 100%;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}

.player-progress[state="loaded"] {
  opacity: 0;
}

.player-progress-fill {
  width: 0%;
  height: 100%;
  -o-transition: .2s;
  background-color: #cde;
  transition: all .2s;
}

.player-progress[state="error"] .player-progress-fill {
  background: #eaa;
}

.player-progress[theme="dark"] .player-progress-fill {
  background-color: #234;
}

.player-progress[theme="dark"][state="error"] .player-progress-fill {
  background: #411;
}

.player-controls .player-progress {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.player-click-to-play-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: all;
  background-color: #00000040;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.player-click-to-play-icon {
  width: 6rem;
  height: 6rem;
  background-image: url("click-to-play.c1ded46a.svg");
  background-size: 100% 100%;
}

.phosphorus-cloud-status-indicator {
  width: 100%;
  color: #fff;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  text-align: center;
  z-index: 10;
  background-color: #000000bf;
  font-size: 1.2em;
  position: absolute;
  top: 0;
  left: 0;
}

.phosphorus-cloud-status-indicator.phosphorus-cloud-status-indicator-hidden {
  opacity: 0;
  transition: opacity .5s 1s;
}

/*# sourceMappingURL=index.50ff22cd.css.map */
