.forkphorus-root {
  position: relative;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  width: 480px;
  height: 360px;
  font-size: 10px;
  background-color: #fff;
  color: black;
  overflow: hidden;
  user-select: none;
  color-scheme: light;
}

.forkphorus-root > * {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  height: 100%;
}

/* Scratch 3 Variable Watchers */
.s3-watcher-container {
  position: absolute;
  top: 0;
  left: 0;
  color: rgb(87, 94, 117);
  border: 0.1em solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  background: hsl(215, 100%, 95%);
  pointer-events: auto;
  line-height: 1.25;
}
.s3-watcher-container > * {
  font-size: 1.2em;
}
.s3-watcher-row {
  display: flex;
  flex-direction: row;
}
.s3-watcher-label {
  font-weight: bold;
  text-align: center;
  margin: 0 5px;
}
.s3-watcher-value {
  color: white;
  text-align: center;
}
.s3-watcher-container .s3-watcher-value {
  /* fallback color */
  background-color: rgb(15, 189, 140);
}
.s3-watcher-container[data-opcode^="data_"] .s3-watcher-value {
  background-color: rgb(255, 140, 26);
}
.s3-watcher-container[data-opcode^="sensing_"] .s3-watcher-value {
  background-color: rgb(92, 177, 214);
}
.s3-watcher-container[data-opcode^="motion_"] .s3-watcher-value {
  background-color: rgb(76, 151, 255);
}
.s3-watcher-container[data-opcode^="looks_"] .s3-watcher-value {
  background-color: rgb(153, 102, 255);
}
.s3-watcher-container[data-opcode^="sound_"] .s3-watcher-value {
  background-color: rgb(207, 99, 207);
}
/* Normal watcher */
.s3-watcher-container-normal {
  padding: 3px;
}
.s3-watcher-row-normal .s3-watcher-value {
  border-radius: 0.25em;
  padding: 0 0.2px;
  margin: 0 0.5em;
  min-width: 4em;
}
/* Slider Watcher (uses normal) */
.s3-watcher-row-slider input[type="range"] {
  margin: 0;
  width: 100%;
}
/* Large watcher */
.s3-watcher-large .s3-watcher-value {
  border-radius: inherit;
  font-size: 1.6em;
  min-height: 1.4em;
  min-width: 3em;
}

/* Scratch 3 list watchers */
.s3-list-container {
  position: absolute;
  color: rgb(87, 94, 117);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: hsla(215, 100%, 95%, 1);
  border: 0.1em solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  pointer-events: none;
  line-height: 1.5;
}
.s3-list-content {
  text-align: center;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  pointer-events: auto;
  /* reset any scrollbar color changes */
  scrollbar-color: auto;
}
.s3-list-content:empty::after {
  content: "(empty)";
  text-align: center;
}
.s3-list-rows {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  contain: layout;
}
.s3-list-endpoint {
  position: absolute;
  width: 1px;
  height: 1px;
  will-change: transform;
  contain: layout;
}
.s3-list-row {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  align-items: center;
  will-change: transform;
  contain: layout;
}
.s3-list-index, .s3-list-value {
  margin: 0 3px;
}
.s3-list-index {
  font-weight: bold;
  pointer-events: none;
}
.s3-list-value {
  background-color: rgb(252, 102, 44);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  flex: 1;
  color: #fff;
  padding: 1px 5px;
  min-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: text;
  white-space: pre;
}
.s3-list-top-label, .s3-list-bottom-label {
  padding: 3px;
  text-align: center;
  font-weight: bold;
  background-color: #fff;
}
.s3-list-top-label {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.s3-list-row, .s3-list-top-label, .s3-list-bottom-label {
  font-size: 1.2em;
}

/* Player Styles */
.player-root {
  color: #000;
  height: auto;
}
.player-root:not([fullscreen]) .player-stage {
  width: 480px;
  height: 360px;
}
.player-root[fullscreen] .player-stage {
  margin-right: calc(var(--lower-width-by) / 2);
  border: 0.1875rem solid rgb(126, 133, 151);
}
/* Player Fullscreen */
.player-root[fullscreen] {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  height: 100%;
  width: 100%;
}
.player-root .player-click-to-play-container {
  display: none;
}
.player-root[running] .player-flag[class][class][class][class], .player-flag[class][class][class][class]:active {
  background-color: hsla(215, 100%, 65%, 0.35);
  border-radius: 0.25rem;
}
/* .player-root[fullscreen] .player-stage {
  width: calc(100vw - var(--lower-width-by));
  height: calc(100% - 1rem - var(--lower-height-by));
} */
.player-body-fullscreen {
  overflow: hidden;
}
/* Player Errors */
.player-root .player-error {
  color: rgb(128, 0, 0);
  font: 500 12px sans-serif;
  box-sizing: border-box;
  padding: 8px;
  position: relative;
  top: 100%;
  left: 0;
  transform: translateY(-100%);
  width: 100%;
  background-color: rgba(255, 200, 200, 0.9);
  backdrop-filter: blur(2px);
  pointer-events: auto;
}
.player-error a {
  color: rgb(100, 0, 0);
}
.player-error a:active {
  color: rgb(24, 67, 210);
}
.player-root[theme=dark] .player-error {
  color: rgb(255, 91, 91);
  background-color: rgba(40, 8, 8, 0.85);
}
.player-root[theme=dark] .player-error a {
  color: rgb(210, 24, 24);
}
.player-root[theme=dark] .player-error a:active {
  color: rgb(24, 120, 210);
}

/* Player Controls */
.player-controls {
  position: relative;
  height: 32px;
  overflow: hidden;
}
.player-controls .player-button {
  width: 32px;
  height: 32px;
  float: left;
  cursor: pointer;
  text-align: center;
  opacity: 0.4;
  background-image: url(../assets/forkphorus-icons.svg);
  text-decoration: none;
}
.player-root[theme=dark] .player-button {
  background-position-y: -32px;
  opacity: 0.6;
}
.player-controls .player-button:active {
  opacity: 1;
}
.player-controls .player-label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  color: rgba(0, 0, 0, 0.4);
  font: 500 12px/32px sans-serif;
  padding: 0 8px;
}
.player-root[theme=dark] .player-label {
  color: rgba(255, 255, 255, 0.6);
}
.player-controls .player-fullscreen-btn {
  float: right;
  background-position: -128px -0px;
}
.player-root[fullscreen] .player-controls .player-fullscreen-btn {
  background-position-x: -160px;
}
.player-root[fullscreen] .control-background-thingy {
  display: block;
}
.player-controls .player-flag {
  background-position: 0px -0px;
}
.player-controls .player-stop {
  background-position: -96px -0px;
}
.player-controls .player-pause {
  background-position: -32px -0px;
}
.player-root:not([running]) .player-controls .player-pause {
  background-position-x: -64px;
}
.player-controls .player-turbo {
  display: none;
}
.player-root[turbo] .player-turbo {
  display: block;
  position: absolute;
  left: 64px;
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 0.625em;
}
.player-controls .player-muted {
  float: left;
  cursor: pointer;
  display: none;
}
.player-controls .player-muted:hover {
  text-decoration: underline;
}
@keyframes player-appear {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}
.player-root[running][audio-state="suspended"] .player-controls .player-muted {
  display: block;
  animation-name: player-appear;
  animation-timing-function: steps(1, end);
  animation-duration: 0.5s;
}
.control-background-thingy {
  width: 100vw;
  height: 2.75rem;
  position: absolute;
  left: 0;
  background-color: rgb(232, 237, 241);
  display: none;
}

/* Player Progress Bar */
.player-progress {
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  -moz-transition: opacity .2s;
  transition: opacity .2s;
}
.player-progress[state=loaded] {
  opacity: 0;
}
.player-progress-fill {
  width: 0%; /* initial width */
  height: 100%;
  background-color: #cde;
  -webkit-transition: .2s;
  -o-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}
.player-progress[state=error] .player-progress-fill {
  background: #eaa;
}
.player-progress[theme=dark] .player-progress-fill {
  background-color: #234;
}
.player-progress[theme=dark][state=error] .player-progress-fill {
  background: #411;
}
.player-controls .player-progress {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Player Click To Play */
.player-click-to-play-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.player-click-to-play-icon {
  width: 6rem;
  height: 6rem;
  background-image: url(../assets/click-to-play.svg);
  background-size: 100% 100%;
}

/* Cloud variable status */
.phosphorus-cloud-status-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1.2em;
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(2px);
  text-align: center;
  z-index: 10;
}
.phosphorus-cloud-status-indicator.phosphorus-cloud-status-indicator-hidden {
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 1s;
}
